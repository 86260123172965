import React from 'react';
import LogoDebtAdvisor from '../images/logos/logo-debt-advisor-with-icon.png';
import LogoTurboDebt from '../images/logos/logo-turbo-debt.png';

export default function debtSettlementOffers() {
    return {
        offers: [{
            logo: LogoTurboDebt,
            company: 'Turbo Debt',
            link: 'https://www.ac934intrk.com/3J67C/6DC976/?sub3=61447',
            rating: 5,
            details: [{
                header: 'Better Business Bureau',
                content: 'A+',
            }, {
                header: 'Years in Business',
                content: '11',
            }, {
                header: 'Free Consultation?',
                content: 'Yes',
            }],
            mainValueProp: 'Let Turbo Debt take the lead on reducing your debt today!',
            benefits: [
                {
                    item: (<>100% Free Initial Consultation</>),
                },
                {
                    item: (<>Free Savings Estimate with No Obligation</>),
                },
                {
                    item: (<>You Could Be Debt-Free in as Little As 24-48 Months</>),
                },
                {
                    item: (<>Click here for official site, terms and details</>),
                    link: true,
                },
            ],
            review: (
                <>
                    <p>Turbo Debt offers debt relief help so you can take control of your finances and work towards a debt-free life. We understand the challenges you face in regaining financial stability and provide affordable solutions to get you out of debt faster than you’d expect.</p>
                </>
            ),
        },{
            logo: LogoDebtAdvisor,
            company: 'Debt Advisor',
            link: 'https://www.ac934intrk.com/3J67C/37GQ4B/?sub3=60456',
            rating: 5,
            details: [{
                header: 'Better Business Bureau',
                content: 'Not rated',
            }, {
                header: 'Years in Business',
                content: '6+',
            }, {
                header: 'Free Consultation?',
                content: 'Yes',
            }],
            mainValueProp: 'Reduce your debt with Debt Advisor',
            benefits: [
                {
                    item: 'No-cost consultations',
                },
                {
                    item: 'Cut your credit card payments by up to 50%',
                },
                {
                    item: 'Receive funds as soon as the next business day!',
                },
                {
                    item: 'Click here for official site, terms, and details',
                    link: true,
                },
            ],
            review: (
                <>
                    <p>With over 1 million debt relief requests yearly, Debt Advisor is one of several owned and operated brands connecting consumers seeking debt relief with financial service providers nationwide. Financial problems arise and we help consumers f ind a solution for their debt relief needs.</p>
                </>
            ),
        }],
    };
}


