import React from 'react';
import { Link, graphql } from 'gatsby';
import {
    Card,
    Container,
    Divider,
    Feed,
    Header,
    Image,
    Menu,
    Segment,
} from 'semantic-ui-react';
import _ from 'lodash';
import Layout from '../components/layout';
import Helmet from '../components/Helmet';
import * as DefaultTemplate from '../components/LandingPages/DefaultTemplate';
import WhitePaperImage1 from '../images/whitepapers/debt-settlement/everything-you-need-to-know-about-debt-settlement-0.png';
import WhitePaperImage2 from '../images/whitepapers/debt-settlement/u-s-states-with-the-most-bankruptcies-2017-1.png';
import WhitePaperImage3 from '../images/whitepapers/debt-settlement/average-american-debt-2.png';
import WhitePaperImage4 from '../images/whitepapers/debt-settlement/how-often-do-creditors-or-debt-collectors-contact-consumers-2017-3.png';
import WhitePaperImage5 from '../images/whitepapers/debt-settlement/types-of-loans-consumers-were-contacted-about-by-debt-collectors-2017-4.png';
import WhitePaperImage6 from '../images/whitepapers/debt-settlement/u-s-bankruptcy-filings-5.png';
import WordPressPostCard from '../components/WordPress/WordPressPostCard';
import WordPressRelatedLink from '../components/WordPress/WordPressRelatedLink';
import OptionsTemplate from '../components/ContentPages/OptionsTemplate';
import debtSettlementOffers from '../data/debtSettlementOffers';
import AdvertiserDisclosurePopUp from '../components/Disclosures/AdvertiserPopUp';


const body = {
    section1: {
        key: '1',
        title: 'Everything You Need to Know About Debt Settlement',
        content: (
            <>
                <Divider hidden className="half-size" />
                <Header as="h3">Everything You Need to Know About Debt Settlement</Header>
                <p><Image src={WhitePaperImage1} alt="Everything You Need to Know About Debt Settlement" /></p>
                <p>At a certain point, people who are in serious debt realize they need help.</p>
                <p>Their problem probably wasn&rsquo;t reckless spending. Maybe it all started when they were laid-off from work or had their hours cut back.</p>
                <p>Unexpected expenses like medical bills, car repairs, and vet invoices mean multiple credit cards get maxed out. Just paying the interest is a challenge.</p>
                <p>
                    It becomes a struggle to make monthly payments on
                    {' '}
                    <a 
                        href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&sub1=debt-settlement&sub2=student-loans"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        student loans
                    </a> 
                    {' '}
                    and unsecured
                    {' '}
                    <a 
                        href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&sub1=debt-settlement&sub2=personal-loans"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        personal loans
                    </a>
                    . Installments are missed and keeping up with them doesn&rsquo;t get any easier.
                </p>
                <p>The math is depressing when the total debt is more than half of a person&rsquo;s annual income.</p>
                <p>It could take decades to get rid of high-interest debt just by making the minimum payments.</p>
                <p>Whenever the phone rings, it&rsquo;s a collection agency calling back yet again.</p>
                <p>
                    Many
                    {' '}
                    <Link to="/bankruptcy/">people in similar debt scenarios choose bankruptcy</Link>
                    .
                </p>
                <p>97% of bankruptcies in the United States today are personal bankruptcies. In 2016, 793,932 people filed for personal bankruptcy in this country.</p>
                <p><Image src={WhitePaperImage2} alt="U.S. States With The Most Bankruptcies (2017)" /></p>
                <p>For some of those cases, if the debtor had reached out to a non-profit debt settlement company for help, bankruptcy might have been avoided</p>
                <p>Debt settlement companies can provide the last line of defense before throwing in the financial towel through bankruptcy.</p>
                <p>These organizations will contact your creditors and get them to accept full payment on a lower total.</p>
                <p>After all, getting something is better than the big fat nothing they&rsquo;d get if you went bankrupt.</p>
                <p>You might have already tried every other possible solution, from debt consolidation and balance transfers to debt management plans. If those didn&rsquo;t work, debt settlement is still an option.</p>
                <p>Look over these debt settlement tips and consider giving this final debt solution a try before declaring bankruptcy.</p>
            </>
        ),
    },
    section2: {
        key: '2',
        title: 'When to Consider Debt Settlement',
        content: (
            <>
                <Divider hidden className="half-size" />
                <Header as="h3">
                    When to Consider Debt Settlement
                    <Header.Subheader>Debt settlement is a last-ditch solution when debt becomes too overwhelming</Header.Subheader>
                </Header>
                <p>Debt settlement is not something we recommend for just anyone who is experiencing debt.</p>
                <p>
                    <strong>Do you feel like you&rsquo;re drowning?</strong>
                    {' '}
                    If you&rsquo;re on the verge of bankruptcy and can&rsquo;t even tread water to keep up minimum payments on your debts, debt settlement could be the answer.
                </p>
                <p>
                    <strong>Prepare yourself.</strong>
                    {' '}
                    It&rsquo;s not pretty, and your credit score will get killed.
                </p>
                <p>
                    <strong>Better than bankruptcy.</strong>
                    {' '}
                    Debt settlement does help you avoid the credit-destroying move to declare bankruptcy. You also avoid the need to hire an attorney.
                </p>
                <Header as="h3">Consider debt settlement when your unsecured debts total more than half your income</Header>
                <p>One of the easiest ways to calculate if it might be time to reach out for debt settlement services is to compare your level of unsecured debt to your income.</p>
                <p>If you are carrying unsecured debt totaling more than half of your annual salary, you&rsquo;re in pretty big trouble.</p>
                <p>It will be next to impossible to make your debt disappear, even using debt repayment plans like the snowball or avalanche method.</p>
                <p>
                    <strong>Monthly payments suck up cash.</strong>
                    {' '}
                    The combined monthly minimum payments across several debts will total more than your cash flow can afford.
                </p>
                <p>
                    <strong>Seek debt settlement when the numbers tell you to.</strong>
                    {' '}
                    Let&rsquo;s say you&rsquo;ve got an unpaid balance on one 
                    {' '}
                    <a 
                        href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&sub1=debt-settlement&sub2=credit-card"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        credit card
                    </a>
                    {' '}
                    for $5,000. The interest you&rsquo;re being charged on it is 15.99%. The monthly minimum is $200.
                </p>
                <p>When you got sick you needed to pay for medical treatment, so you took out another card to cover your health insurance plan&rsquo;s $4,000 deductible. It&rsquo;s gathering interest at an APR of 17.99%. The monthly minimum is $160.</p>
                <p>
                    Your private
                    {' '}
                    <a 
                        href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&sub1=debt-settlement&sub2=student-loan"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        student loan
                    </a> 
                    {' '}
                    still isn&rsquo;t paid off. You&rsquo;ll be paying $146.11 monthly installments on $10,000 in remaining debt at 6% interest for the next seven years.
                </p>
                <p>
                    You took out an unsecured
                    {' '}
                    <a 
                        href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&sub1=debt-settlement&sub2=personal-loan"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        personal loan
                    </a> 
                    {' '}
                    to make ends meet when your employer cut back your hours. The debt is being charged at 9.99% interest, and currently sits at $15,000. The lender is expecting monthly payments of $318.12 for the next five years.
                </p>
                <p>Your salary is $52,000 a year pre-tax.</p>
                <p>
                    <strong>More than a decade to pay off the credit card debts.</strong>
                    {' '}
                    For the $5,000 credit card debt, if you maintain the minimum monthly payments it will take 10.5 years to pay it off. The $4,000 credit card balance will take 10 years and 4 months to pay off.
                </p>
                <p>
                    <strong>Monthly payments are back-breaking.</strong>
                    {' '}
                    Your combined total minimum monthly payments for all the debts is $824.23. With all the other costs of living, you can barely make it.
                </p>
                <p>
                    <strong>Unsecured debt equals 65% of income.</strong>
                    {' '}
                    Your total current debt load is $34,000 and your annual income is $52,000.
                </p>
                <p>
                    <strong>The numbers aren&rsquo;t lying.</strong>
                    {' '}
                    It&rsquo;s time to do something while there&rsquo;s still time.
                </p>
                <Header as="h3">Before you turn to debt settlement, try every other debt solution</Header>
                <p>Hopefully, you take the opportunity to do something about your debt before it gets out-of-control like the scenario above.</p>
                <p><Image src={WhitePaperImage3} alt="Average American Debt" /></p>
                <p>Debt settlement should only be pursued if all other possible solutions are not going to work.</p>
                <Header as="h3">Try debt consolidation or creating a debt management plan first</Header>
                <p>There are a few different tools to consolidate debt. All of them involve bringing your multiple existing debts under one account.</p>
                <p>
                    <strong>Benefits of one loan rather than multiple accounts.</strong>
                    {' '}
                    The goals of debt consolidation are to make the monthly payment simpler and cheaper by combining all your debts into a single payment, ideally with an overall lower interest rate.
                </p>
                <p>
                    <strong>Debt consolidation loans are widely available.</strong>
                    {' '}
                    Whether you go to a bank or to an online peer-to-peer lending site,
                    {' '}
                    <Link to="/loans/">loans are available</Link>
                    {' '}
                    if your credit score is still intact.
                </p>
                <p>Borrow the full amount of your debt, use it to pay off your creditors, and then focus on paying off the one loan.</p>
                <p>Ideally, you&rsquo;ll get a better interest rate than the average of your existing loans, and the monthly payment is less than the previous combined payments.</p>
                <p>
                    <strong>Home equity loans let you borrow off the value in your home.</strong>
                    {' '}
                    If you&rsquo;ve been faithfully keeping up with mortgage payments for years, you&rsquo;ve built equity in your home.
                </p>
                <p>You can borrow from the equity you&rsquo;ve accumulated and wipe out your existing debt. Then you pay off the second mortgage in installments at a really low-interest rate.</p>
                <p>
                    <strong>Pay off credit card debts interest-free with a balance transfer card.</strong>
                    {' '}
                    There are lots of
                    {' '}
                    <Link to="/credit-card-debt/">balance transfer credit cards available</Link>
                    . They all feature an introductory period (usually 12 months but up to 21 months) with 0% APR.
                </p>
                <p>Get a new card and transfer your existing balances to it. If you can pay it off during the 0% APR period, 
                    {' '}
                    <a 
                        href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=48&sub1=debt-settlement&sub2=youll-be-saving"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        you'll be saving
                    </a>
                    {' '}
                    a lot of interest.</p>
                <p>
                    <strong>Look out for interest and fees.</strong>
                    {' '}
                    Just watch for balance transfer fees (usually 3–5% of the amount transferred) and getting stuck with a high APR once the intro period is over.
                </p>
                <p>
                    <strong>Debt management plans lower your rates and structure your payments.</strong>
                    {' '}
                    Don&rsquo;t confuse debt settlement services with debt management plans. Both are offered through non-profit organizations as debt solutions.
                </p>
                <p>There are key differences you should know about:</p>
                <p>
                    <strong>Debt management plans reduce the interest, not the principle.</strong>
                    {' '}
                    When a
                    {' '}
                    <Link to="/credit-counseling/">credit counselor</Link>
                    {' '}
                    sets up a
                    {' '}
                    <Link to="/debt-settlement/debt-management-plan/">debt management plan</Link>
                    {' '}
                    they contact your creditors.
                </p>
                <p>They don&rsquo;t try to talk your creditors into reducing the total amount of the debt. They do negotiate lower interest rates, waived late fees, and monthly payments geared to income.</p>
                <p>You then submit your monthly payment to the credit counseling agency and each debt gets paid when there&rsquo;s enough to pay it off in full. The debtor is charged a fee for this service.</p>
            </>
        ),
    },
    section3: {
        key: '3',
        title: 'How Debt Settlement Works',
        content: (
            <>
                <Divider hidden className="half-size" />
                <Header as="h3">
                    How Debt Settlement Works
                    <Header.Subheader>
                        Debt settlement shrinks your total debt
                    </Header.Subheader>
                </Header>
                <p>If you&rsquo;re too deep in debt to apply the alternative solutions we listed above, it&rsquo;s time to contact a debt settlement agency.</p>
                <p>
                    <strong>Reduce the debt.</strong>
                    {' '}
                    Rather than contacting your creditors to negotiate a lower interest rate (as was the case with the debt management plan), debt settlement companies negotiate to pay less than the total debt.
                </p>
                <p><Image src={WhitePaperImage4} alt="How Often Do Creditors or Debt Collectors Contact Consumers? (2017)" /></p>
                <p>
                    <strong>You only qualify if you&rsquo;re really hurting.</strong>
                    {' '}
                    Proof of your financial hardship needs to be supplied. When the creditors see how close you are to bankruptcy, they&rsquo;ll accept a drastically reduced amount. After all, something is better than nothing.
                </p>
                <p>The new amount the companies negotiate will also be more than a collection agency would buy the debt for from the creditor.</p>
                <Header as="h3">Debt settlement services deal directly with the creditors</Header>
                <p>One of the big advantages of using a debt settlement service is the agency contacts your creditors on your behalf.</p>
                <p>
                    <strong>You get a special account.</strong>
                    {' '}
                    You pay into an account set up by the debt settlement company, and when there&rsquo;s enough in it to pay the agreed-upon lowered amount, the agency sends it to the lenders.
                </p>
                <p>
                    <strong>Relationships matter.</strong>
                    {' '}
                    The debt settlement agency usually has an existing relationship with lenders like credit card companies. It can usually negotiate a much lower payment as part of a larger bulk package with the creditor.
                </p>
                <p>In addition to charging you a fee, the debt settlement company will make some of its money as a percentage of the reduced total debt it has negotiated.</p>
                <p>Here&rsquo;s exactly how debt settlement works:</p>
                <p>The debt settlement company negotiates a reduced balance with your creditors. Paying the reduced amount will be considered payment in full.</p>
                <p>
                    <strong>Make your installments to the agency, not the creditors.</strong>
                    {' '}
                    Monthly payments are made to the debt settlement company.
                </p>
                <p>The debt settlement company might choose not to pay an existing debt until the account is in default for no-payment. This gives them more leverage to negotiate the new debt total.</p>
                <p>The debt settlement agency sets up a deposit account insured by the Federal Deposit Insurance Corporation (FDIC). Monthly payments (minus agency fees) are deposited in the trust account.</p>
                <p>
                    <strong>Lump sum payments.</strong>
                    {' '}
                    When the account has accumulated enough money to pay off a debtor, a lump sum for the reduced balance is submitted by the debt settlement company.
                </p>
                <p>To illustrate, let&rsquo;s use the $34,000 debt we talked about earlier as an example again.</p>
                <p>
                    The settlement company will contact the credit card companies and the private lenders for the
                    {' '}
                    <a 
                        href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&sub1=debt-settlement&sub2=personal"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        personal
                    </a> 
                    {' '}
                    and
                    {' '}
                    <a 
                        href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&sub1=debt-settlement&sub2=student"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        student
                    </a> 
                    {' '}
                    loans.
                </p>
                <p>
                    <strong>Debt reduced by half.</strong>
                    {' '}
                    After proving how badly you are doing financially, the $34,000 total debt will be negotiated down to half at $17,000.
                </p>
                <p>A fee is charged by the debt settlement company (anywhere from 10–75% of the total amount of debt to be settled).</p>
                <p>
                    <strong>Monthly payments with no interest.</strong>
                    {' '}
                    A monthly payment is determined, and interest is no longer charged as long as the debtor sticks to the payment plan.
                </p>
                <p>If the debt settlement company charges a 20% fee on the entire debt it will add $3,400 to the reduced balance, for a total of $20,400.</p>
                <p>With monthly payments of $340, the entire debt can be gone within five years.</p>
            </>
        ),
    },
    section4: {
        key: '4',
        title: 'The Effects of Debt Settlement',
        content: (
            <>
                <Divider hidden className="half-size" />
                <Header as="h3">
                    The Effects of Debt Settlement
                    <Header.Subheader>Your credit score is going to get pounded during debt settlement</Header.Subheader>
                </Header>
                <p>The big downside of debt settlement is the damage your credit score will experience.</p>
                <p>Credit scores are calculated based on a mix of credit behavior factors to determine a borrower&rsquo;s trustworthiness.</p>
                <Header as="h3">Debt settlement steps are not credit-score friendly</Header>
                <p>Having debts go into default (a common practice in debt settlement) hurts your credit score.</p>
                <p>
                    <strong>A tip for saving your score.</strong>
                    {' '}
                    If a debt is not officially &ldquo;paid in full&rdquo; it will negatively impact your credit score. Requesting a "paid in full" confirmation document from lenders is one strategy for reducing the harm your credit score will experience.
                </p>
                <p>
                    <strong>The phone will ring.</strong>
                    {' '}
                    There is a good chance the
                    {' '}
                    <Link to="/debt-collection/">collection agencies will continue to call</Link>
                    {' '}
                    or start to call for new reasons during the debt settlement process.
                </p>
                <p><Image src={WhitePaperImage5} alt="Types of Loans Consumers Were Contacted About By Debt Collectors (2017)" /></p>
                <p>Once again, the reason is due to the debt settlement agency allowing your debt to lapse into default status. As the debts are renegotiated and paid off, these calls will slow down and eventually go away altogether.</p>
                <p>
                    <strong>Watch your credit score fall before your eyes.</strong>
                    {' '}
                    There&rsquo;s a really good chance your credit score is already low if you&rsquo;re requiring debt settlement.
                </p>
                <p>Using all your available credit hurts your score. Missing or late payments reduce it further.</p>
                <p>
                    <strong>Times once were good.</strong>
                    {' '}
                    You probably had at least a &ldquo;Good&rdquo; credit rating at one time (with a FICO credit score between 650 and 700). You&rsquo;d need it to access all the credit you built up.
                </p>
                <p>
                    <strong>Now things are bad.</strong>
                    {' '}
                    After debt settlement, don&rsquo;t be surprised if you check your credit score and it&rsquo;s taken a dive into the levels of &ldquo;Fair&rdquo; (600 to 700), &ldquo;Bad&rdquo; (560 to 650) or &ldquo;Very Bad&rdquo; (300 to 560).
                </p>
                <p>Some of the steps you might have taken during debt settlement, such as defaulting on a loan, can continue to negatively impact your credit score for seven years.</p>
                <p>
                    <strong>Stick to it!</strong>
                    {' '}
                    Eventually, you can
                    {' '}
                    <Link to="/repair-your-credit/">build your credit score back up</Link>
                    . The key is to keep up with your payments and put some time between your debt settlement and applying for new credit.
                </p>
                <p>It&rsquo;s an unfortunate fact that half of the people who have a debt settlement plan longer than 36 months drop out. If a plan is less than 36 months the completion rate is much higher at 85%.</p>
                <Header as="h3">A non-profit debt settlement company will put your interests ahead of its profits</Header>
                <p>There are two different kinds of debt settlement agencies, non-profit and for-profit.</p>
                <p>
                    <strong>No commission is a must.</strong>
                    {' '}
                    Credit counselors at non-profit debt settlement agencies are not working on commission.
                </p>
                <p>For-profit agencies are criticized for offering advice, products, and services benefitting the company&rsquo;s bottom line rather than the client&rsquo;s interests.</p>
                <Header as="h3">A non-profit debt settlement service will have your back</Header>
                <p>A good debt settlement company will set up a monthly call to check in with you while you&rsquo;re paying off the debt.</p>
                <p>
                    <strong>Flexible plans available.</strong>
                    {' '}
                    The credit counselor will set up a plan with you. It might be flexible with the option to miss a payment or two if required. You could also plan for the option of paying the debt off sooner if an opportunity arises.
                </p>
                <p>
                    <strong>Look for the National Foundation For Credit Counseling (NFCC) affiliation.</strong>
                    {' '}
                    That likely means it&rsquo;s a reputable non-profit debt settlement company. All members of the organization are 501(c)3 registered non-profit debt relief companies.
                </p>
                <p>There are many unethical, profit-driven organizations in the debt relief industry.</p>
                <Header as="h3">Debt settlement is no walk in the park, but it usually beats bankruptcy</Header>
                <p>As you&rsquo;ve learned, going through debt settlement is not pretty.</p>
                <p>It requires you to suck it up and take a big hit on your credit score.</p>
                <p>Your creditors won&rsquo;t be happy until you pay them the reduced amount.</p>
                <p>We only recommend debt settlement to people who have already been through the credit ringer and tried every other possible solution.</p>
                <p>Filing for bankruptcy can harm your credit score even more. It can take ten years before the unpaid accounts disappear from your credit report after a bankruptcy.</p>
                <p><Image src={WhitePaperImage6} alt="U.S. Bankruptcy Filings" /></p>
                <p>Bankruptcies are also expensive. You&rsquo;ll pay legal fees and there is still a chance the judge will require you to repay some of your creditors (for example, the student loan).</p>
                <p>If there&rsquo;s no other option available to you, make sure you find a reputable non-profit debt settlement service that will protect your interests.</p>
            </>
        ),
    },
};

const panels = [
    {
        key: body.section1.key,
        title: body.section1.title,
        content: {
            content: (
                body.section1.content
            ),
        },
    },
    {
        key: body.section2.key,
        title: body.section2.title,
        content: {
            content: (
                body.section2.content
            ),
        },
    },
    {
        key: body.section3.key,
        title: body.section3.title,
        content: {
            content: (
                body.section3.content
            ),
        },
    },
    {
        key: body.section4.key,
        title: body.section4.title,
        content: {
            content: (
                body.section4.content
            ),
        },
    },
];

export const query = graphql`
    query {
        loopTriple: allWordpressPost(
            filter: {
                slug: {
                    in: ["/debt-relief/", "/debt-settlement/accredited-debt-relief-review/", "/debt-settlement/debtmd/"]
                }
            }
        ) {
            edges {
                node {
                    ...RelatedWordpressPosts
                }
            }
        }
        loopRelated: allWordpressPost(
            filter: {
                slug: {
                    in: [
                        "/stop-debt-collector-calls/"
                        "/credit-card-debt/"
                        "/how-to-get-out-of-debt/"
                    ]
                }
            }
        ) {
            edges {
                node {
                    ...RelatedWordpressPosts
                }
            }
        }
    }
`;

const DebtSettlementPage = (props) => {
    return (
        <Layout
            showInformationDisclosure={true}
        >
            <Helmet
                title="Debt Settlement"
                description="Is debt settlement your best choice to get out of debt? Learn the why, hows, and how much of debt settlement and see if it's the option for you."
            />
            <DefaultTemplate.Wrapper>
                <DefaultTemplate.Hero>
                    <div>
                        <Segment attached>
                            <Container fluid className="hero hero__debt-settlement" textAlign="center">
                                <Container text>
                                    <AdvertiserDisclosurePopUp />
                                    <Header as="h1" size="large">Debt Settlement</Header>
                                    <Header as="h2" size="medium">Take steps to negotiate your repayment terms</Header>
                                </Container>
                            </Container>
                        </Segment>
                        <Menu pointing attached>
                            <Menu.Item href="/">Home</Menu.Item>
                            <Menu.Item href="/loans/">Debt Consolidation</Menu.Item>
                            <Menu.Item href="/debt-settlement/" active>Debt Settlement</Menu.Item>
                            <Menu.Item href="/credit-counseling/">Credit Counseling</Menu.Item>
                        </Menu>
                    </div>
                </DefaultTemplate.Hero>

                <DefaultTemplate.Featured>
                    <OptionsTemplate items={debtSettlementOffers()}  />
                    <Divider hidden />
                    <Container textAlign="center">
                        <Header size="large" textAlign="center">
                            Learn About Debt
                        </Header>
                        <div className="content--featured">
                            <Feed>
                                <WordPressRelatedLink data={props.data.loopTriple.edges[0].node} />
                                <WordPressRelatedLink data={props.data.loopTriple.edges[1].node} />
                                <WordPressRelatedLink data={props.data.loopTriple.edges[2].node} />
                            </Feed>
                        </div>
                        <div className="content--featured__desktop">
                            <Card.Group stackable itemsPerRow={3}>
                                {_.map(props.data.loopTriple.edges, data => <WordPressPostCard data={data.node} key={data.node.id} />)}
                            </Card.Group>
                        </div>
                    </Container>
                </DefaultTemplate.Featured>

                <DefaultTemplate.Body>
                    {panels.map((item, idx) => (
                        <div key={idx}>
                            {item.content.content}
                        </div>
                    ))}
                    <Divider hidden />
                </DefaultTemplate.Body>

                <DefaultTemplate.PostSidebar>
                    <Header size="medium">
                        Related Content
                    </Header>
                    <Feed>
                        <WordPressRelatedLink data={props.data.loopRelated.edges[0].node} />
                        <WordPressRelatedLink data={props.data.loopRelated.edges[1].node} />
                        <WordPressRelatedLink data={props.data.loopRelated.edges[2].node} />
                    </Feed>
                </DefaultTemplate.PostSidebar>
            </DefaultTemplate.Wrapper>
        </Layout>
    );
};

export default DebtSettlementPage;
